'use client';
/*
Copyright (C) 2009 - 2021 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Option } from '../common/form/SelectField';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import {
  getCountriesAndStateProvinceRegionsEndpoint,
  USCountries
} from '../utils/AddressUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';

type Response = {
  isFetching: boolean;
  error: AxiosError;
  countrySelectOptions: Array<Option>;
  subdivisionSelectOptions: SubdivisionMap;
};

export type SubdivisionMap = {
  [key: string]: Array<Option>;
};

export const useFetchCountryData = (metadata: IMetadata): Response => {
  const [countryData, setCountryData] = useState(USCountries);
  const contextParams = useContextParams(metadata);

  const countrySelectOptions = useMemo(() => {
    return countryData.map(({ alpha2, name }) => ({
      value: alpha2,
      label: name
    }));
  }, [countryData]);

  const subdivisionSelectOptions = useMemo(() => {
    return countryData.reduce(
      (a, { alpha2, subdivisions }) => ({
        ...a,
        [alpha2]: subdivisions.map(({ value, name }) => ({
          value,
          label: name
        }))
      }),
      {}
    );
  }, [countryData]);

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchCountryData = useEventCallback(async () => {
    if (isFetching) {
      return;
    }

    try {
      setIsFetching(true);
      setError(null);
      const endpoint = getCountriesAndStateProvinceRegionsEndpoint();

      const { data: response } = await request(
        {
          data: {},
          ...endpoint
        },
        contextParams
      );
      setCountryData(response);
    } catch (ex) {
      if (axios.isCancel(ex)) {
        // do nothing if cancelled
        return;
      }
      setError(ex);
    } finally {
      setIsFetching(false);
    }
  }, [contextParams]);

  useEffect(() => {
    fetchCountryData();
  }, [fetchCountryData]);

  return {
    isFetching,
    error,
    countrySelectOptions,
    subdivisionSelectOptions
  };
};
