'use client';
/*
Copyright (C) 2009 - 2021 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Option } from '../common/form/SelectField';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';
import { getDefaultShipmentOptionsEndpoint } from '../../oms/FulfillmentView/util/FulfillmentOperationUtils';
import { ShipmentOptions } from '../types/FulfillmentTypes';

type Response = {
  isFetching: boolean;
  error: AxiosError;
  defaultShipmentOptions: ShipmentOptions;
};

export const useFetchDefaultShipmentOptions = (
  fulfillmentId: string,
  metadata: IMetadata
): Response => {
  const [defaultShipmentOptions, setDefaultShipmentOptions] = useState();
  const contextParams = useContextParams(metadata);

  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);

  const fetchDefaultShipmentOptions = useEventCallback(async () => {
    try {
      setIsFetching(true);
      setError(null);
      const endpoint = getDefaultShipmentOptionsEndpoint(fulfillmentId);

      const { data: response } = await request(
        {
          data: {},
          ...endpoint
        },
        contextParams
      );
      setDefaultShipmentOptions(response);
    } catch (ex) {
      if (axios.isCancel(ex)) {
        // do nothing if cancelled
        return;
      }
      setError(ex);
    } finally {
      setIsFetching(false);
    }
  }, [fulfillmentId, contextParams]);

  useEffect(() => {
    fetchDefaultShipmentOptions();
  }, [fetchDefaultShipmentOptions]);

  return {
    isFetching,
    error,
    defaultShipmentOptions
  };
};
