import React, { useEffect, useMemo, useState } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { Field as FormikField, FieldAttributes, FieldProps } from 'formik';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { get } from 'lodash';
import cx from 'classnames';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import ToggleSwitch from '@broadleaf/admin-components/dist/form/components/ToggleSwitch';
import {
  Option,
  RMToggleSwitch
} from '../../../components/form/toggle-switch/RMToggleSwitch';

export type ToggleSwitchFieldProps = FieldAttributes<any> & {
  name: string;
  className?: string;
  inputClassName?: string;
  formik: IFormik;
  isDisabled?: boolean;
  options: Option[];
  onChange?: (value) => void;
  label: string;
};
export const ToggleSwitchField = (props: ToggleSwitchFieldProps) => {
  const {
    formik,
    name,
    isDisabled,
    className = '',
    inputClassName = '',
    label,
    options
  } = props;
  const formatMessage = useFormatMessage();
  const metadata = { name, label: label };
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);

  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-1 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations fullWidth {...props} metadata={metadata}>
          <FormikField
            {...props}
            name={name}
            className={cx(inputClassName, {
              'is-invalid': touched && !!error
            })}
            disabled={formik.isSubmitting || isDisabled}
            options={options}
            component={ToggleSwitchFormField}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const ToggleSwitchFormField: React.SFC<ToggleSwitchFieldProps> = ({
  options,
  field,
  form,
  onChange = value => {},
  ...props
}) => {
  return (
    <RMToggleSwitch
      className="tw-"
      disabled={props.isDisabled}
      name={field.name}
      options={options}
      onBlur={form.handleBlur}
      onChange={value => {
        // onChange(value);
        form.setFieldValue(field.name, value);
      }}
      value={field.value}
    />
  );
};
