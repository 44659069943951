import React, { useMemo } from 'react';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import {
  useFormatDate,
  useFormatNumber
} from '@broadleaf/admin-components/dist/common';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import {
  getPurchaseLabelEndpoint,
  getRateShipmentEndpoint
} from '../util/FulfillmentOperationUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import { getTrackableCarriers } from '../util/ShipmentUtils';
import { SelectField } from '../../../custom/common/form/SelectField';
import { InputField } from '../../../custom/common/form/InputField';
import { SubmitButton } from '../../../custom/common/form/SubmitButton';
import { ManageShipmentsSectionProps } from './ManageShipmentsModal';

import { ShippingRate } from '../../../custom/types/FulfillmentTypes';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';
import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import { SimpleIcon } from '@broadleaf/admin-components/dist/common/elements/SimpleIcon';
import { useFetchDefaultShipmentOptions } from '../../../custom/hooks/useFetchDefaultShipmentOptions';
import { LoadingIcon } from '@broadleaf/admin-components/dist/form/components/TreeView/components/NavigatorItem/NavigatorItem';
import { ToggleSwitchField } from '../../../custom/common/form/ToggleSwitchField';

export const GenerateLabelForm: React.FC<
  ManageShipmentsSectionProps
> = props => {
  const { metadata, fulfillment, dispatch, state, onComplete } = props;
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();
  const { defaultShipmentOptions, isFetching, error } =
    useFetchDefaultShipmentOptions(fulfillment.id, metadata);

  const contextParams = useContextParams(metadata);
  const initialValues = useMemo(() => {
    return defaultShipmentOptions;
  }, [defaultShipmentOptions]);
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      //TODO add validation
      // carrierCode: yup.string().required(),
      // trackingNumber: yup.string().required()

      hasDryIce: yup.boolean()
    });
  }, []);
  const [rates, setRates] = React.useState([] as ShippingRate[]);

  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        let endpoint = getRateShipmentEndpoint(props.state.data.id);
        if (rates.length) {
          endpoint = getPurchaseLabelEndpoint(props.state.data.id);
        }

        const requestData = {
          ...values
        };
        if (values.dryIceWeight?.weight) {
          requestData.dryIceWeight = {
            //convert the dryIceWeight to a number
            weight: Number(values.dryIceWeight.weight),
            unit: 'POUND'
          };
        }

        const { data: response } = await request(
          {
            method: 'post',
            data: requestData,
            ...endpoint
          },
          contextParams
        );

        formik.setSubmitting(false);
        clearFormikErrors(formik);
        setRates(response);
        if (rates.length) {
          setTimeout(() => {
            dispatch(queueFetch());
          }, 100);
          formik.resetForm();
          setRates([]);
          onComplete();
        }
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [contextParams, props.state, props.dispatch, props.onClose, rates]
  );

  if (isFetching) {
    return <LoadingIcon />;
  }
  if (error || !defaultShipmentOptions) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <GenerateLabelSection
            formik={formik}
            fulfillment={fulfillment}
            state={state}
            rates={rates}
            setRates={setRates}
          />
        )}
      </Formik>
    </>
  );
};

export type GenerateLabelSectionProps = {
  formik: any;
  fulfillment: IOrderFulfillment;
  state: IFulfillmentViewLocalState;
  rates: ShippingRate[];
  setRates: (rates: ShippingRate[]) => void;
};
export const GenerateLabelSection: React.FC<
  GenerateLabelSectionProps
> = props => {
  const { formik, fulfillment, state, rates } = props;
  const checkAccess = UserAccess.useCheckAccess();
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  const hasAccess = checkAccess('ORDER_FULFILLMENT', 'UPDATE');

  const isDisabled = useMemo(() => {
    return !hasAccess || formik.isSubmitting || state.isFetching;
  }, [hasAccess, formik.isSubmitting, state.isFetching, rates.length]);

  const isFieldsDisabled = useMemo(() => {
    return rates.length > 0 || isDisabled;
  }, [rates.length, isDisabled]);

  const options = getTrackableCarriers(formatMessage);
  return (
    <>
      <div className="tw-flex tw-flex-1 tw-flex-col tw-border-b tw-border-gray-500">
        <div className="tw-flex tw-flex-1 tw-flex-col">
          <div className="tw-flex tw-flex-1 tw-flex-col">
            <ShippingOptionsSection {...props} isDisabled={isFieldsDisabled} />
            <PackagesSection {...props} isDisabled={isFieldsDisabled} />
            <RatesSection {...props} isDisabled={isFieldsDisabled} />
          </div>
          <div className="tw-flex tw-flex-1 tw-flex-row">
            <div className="tw-flex-0 tw-ml-auto tw-flex tw-h-12">
              {rates.length > 0 && (
                <a
                  className="tw-flex-0 tw-my-auto tw-mr-2 tw-cursor-pointer tw-text-sm tw-font-semibold tw-text-primary-500 hover:tw-text-primary-600"
                  onClick={() => {
                    props.setRates([]);
                  }}
                >
                  Clear Rates
                </a>
              )}
              <SubmitButton
                formik={formik}
                isSubmitting={formik.isSubmitting}
                isDisabled={isDisabled}
                submitLabel={rates.length ? 'Purchase Label' : 'Get Rates'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type ShippingOptionsSectionProps = GenerateLabelSectionProps & {
  isDisabled: boolean;
};

const ShippingOptionsSection: React.FC<ShippingOptionsSectionProps> = ({
  formik,
  isDisabled
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const shippingAccountTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'None' },
      { value: 'ups', label: 'UPS' },
      { value: 'fedex', label: 'FedEx' }
    ];
  }, []);
  const booleanToogleOptions = useMemo(() => {
    return [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' }
    ];
  }, []);

  return (
    <div className="tw-my-4 tw-flex tw-flex-1 tw-flex-col tw-border-b tw-border-gray-500">
      <div className="tw-mb-2 tw-flex-1 tw-text-lg">
        Shipping Options{' '}
        <a
          className={classNames(
            'tw-ml-2 tw-cursor-pointer tw-text-sm tw-text-primary-500 hover:tw-text-primary-600',
            {
              'tw-text-primary-500': isEditing
            }
          )}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? 'Done' : 'Edit'}
        </a>
      </div>
      <div className="tw-flex tw-flex-1 tw-gap-2">
        {/*add the shipto and shipfrom addresses vertially*/}
        <div className="tw-flex tw-flex-1 tw-flex-col">
          <div className="tw-mb-2 tw-flex-1">
            <div className="tw-font-semibold">Ship To</div>
            <div>
              {formik.values.shipToAddress.addressLine1}{' '}
              {formik.values.shipToAddress.addressLine2}
            </div>
            <div>
              {formik.values.shipToAddress.city},{' '}
              {formik.values.shipToAddress.stateProvinceRegion}{' '}
              {formik.values.shipToAddress.postalCode}
            </div>
            <div>{formik.values.shipToAddress.country}</div>
            <div>{formik.values.shipToAddress.phonePrimary?.phoneNumber}</div>
          </div>

          <div className="tw-mb-2 tw-flex-1">
            <div className="tw-font-semibold">Ship From</div>
            <div>
              {formik.values.shipFromAddress.addressLine1}{' '}
              {formik.values.shipFromAddress.addressLine2}
            </div>
            <div>
              {formik.values.shipFromAddress.city},{' '}
              {formik.values.shipFromAddress.stateProvinceRegion}{' '}
              {formik.values.shipFromAddress.postalCode}
            </div>
            <div>{formik.values.shipFromAddress.country}</div>
            <div>{formik.values.shipFromAddress.phonePrimary?.phoneNumber}</div>
          </div>
        </div>

        {isEditing ? (
          <>
            <div className="tw-col-span-2 tw-flex tw-flex-1 tw-flex-row">
              <div className="tw-flex-1">
                <SelectField
                  formik={formik}
                  label="Shipping Account Type"
                  name="shippingAccountType"
                  options={shippingAccountTypeOptions}
                  isDisabled={isDisabled}
                />
                <InputField
                  formik={formik}
                  name={`shippingAccountNumber`}
                  isDisabled={isDisabled}
                  label="Shipping Account Number"
                />
                <InputField
                  formik={formik}
                  name={`shippingAccountPostalCode`}
                  isDisabled={isDisabled}
                  label="Shipping Account Postal Code"
                />
              </div>
            </div>
            <div className="tw-flex-1">
              <ToggleSwitchField
                formik={formik}
                name={`saturdayDelivery`}
                isDisabled={isDisabled}
                options={booleanToogleOptions}
                label="Saturday Delivery"
              />
              <ToggleSwitchField
                formik={formik}
                name={`hasDryIce`}
                isDisabled={isDisabled}
                options={booleanToogleOptions}
                label="Has Dry Ice?"
              />
              {formik.values.hasDryIce && (
                <InputField
                  formik={formik}
                  name={`dryIceWeight.weight`}
                  isDisabled={isDisabled}
                  type="number"
                  min="0.01"
                  label="Dry Ice Weight (lbs)"
                />
              )}
            </div>
            <div className="tw-flex tw-flex-1 tw-flex-row">
              <div className="tw-flex-1">
                <ToggleSwitchField
                  formik={formik}
                  name={`includeShippingInsurance`}
                  isDisabled={isDisabled}
                  options={booleanToogleOptions}
                  label="Include Shipping Insurance"
                />
              </div>
            </div>
          </>
        ) : (
          // compact read-only view
          <>
            <div className="tw-flex-1">
              <div className="tw-font-semibold">Shipping Account Type</div>
              <div>
                {!formik.values.shippingAccountType
                  ? 'None'
                  : formik.values.shippingAccountType}
              </div>
              {formik.values.shippingAccountType && (
                <>
                  <div className="tw-font-semibold">
                    Shipping Account Number
                  </div>
                  <div>{formik.values.shippingAccountNumber}</div>
                  <div className="tw-font-semibold">
                    Shipping Account Postal Code
                  </div>
                  <div>{formik.values.shippingAccountPostalCode}</div>
                </>
              )}
            </div>
            <div className="tw-flex-1">
              <div className="tw-font-semibold">Saturday Delivery</div>
              <div>{formik.values.saturdayDelivery ? 'Yes' : 'No'}</div>
              <div className="tw-font-semibold">Has Dry Ice?</div>
              <div>{formik.values.hasDryIce ? 'Yes' : 'No'}</div>
              {formik.values.hasDryIce && (
                <>
                  <div className="tw-font-semibold">Dry Ice Weight (lbs)?</div>
                  <div>{formik.values.dryIceWeight?.weight}</div>
                </>
              )}
            </div>
            <div className="tw-flex-1">
              <div className="tw-font-semibold">Include Shipping Insurance</div>
              <div>{formik.values.includeShippingInsurance ? 'Yes' : 'No'}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type PackagesSectionProps = GenerateLabelSectionProps & {
  isDisabled: boolean;
};
const PackagesSection: React.FC<PackagesSectionProps> = ({
  formik,
  isDisabled
}) => {
  const isInternational = useMemo(() => {
    return formik.values.shipToAddress.country !== 'US';
  }, [formik.values.shipToAddress.country]);
  return (
    <div className="tw-flex tw-flex-1 tw-flex-col">
      <div className="tw-flex tw-flex-1 tw-flex-col">
        <div className="tw-mb-1 tw-flex-1 tw-text-lg">Packages</div>
        {formik.values.packages.map((pkg, index) => {
          return (
            <div className="tw-flex tw-flex-1 tw-flex-row" key={index}>
              <div className="tw-flex-1">
                <InputField
                  formik={formik}
                  label="Package Weight (lbs)"
                  name={`packages[${index}].weight.value`}
                  isDisabled={isDisabled}
                  type="number"
                  hint="If the package is large and not heavy use dimensional weight (inches) LxHxW/139"
                />
              </div>
              <div className="tw-flex-1">
                <InputField
                  formik={formik}
                  name={`packages[${index}].insuredValue.amount`}
                  isDisabled={isDisabled}
                  label="Insured Value"
                />
              </div>
              {index > 0 && (
                <button
                  disabled={isDisabled}
                  onClick={() => {
                    formik.setFieldValue(
                      'packages',
                      formik.values.packages.filter((cli, idx) => {
                        return idx !== index;
                      })
                    );
                  }}
                >
                  <SVG
                    className="tw-mx-auto tw-ml-2 tw-mt-5 tw-h-4 tw-w-4 tw-fill-current tw-text-red-500"
                    name="close-solid"
                  />
                </button>
              )}
            </div>
          );
        })}

        <button
          disabled={isDisabled || isInternational}
          className={classNames(
            'tw-text-md focus:tw-shadow-outline tw-m-auto tw-w-fit tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-py-2',
            {
              'tw-cursor-not-allowed tw-bg-gray-700 hover:tw-bg-gray-600':
                isDisabled || isInternational
            }
          )}
          style={{ opacity: isDisabled ? '0.65' : '1' }}
          onClick={() => {
            formik.setFieldValue('packages', [
              ...formik.values.packages,
              {
                weight: {
                  value: 10,
                  unit: 'POUNDS'
                },
                insuredValue: {
                  amount: 0,
                  currency: 'USD'
                }
              }
            ]);
          }}
        >
          Add Package
        </button>
        {isInternational && (
          <div className="tw-mx-auto tw-w-fit tw-text-center tw-text-red-800">
            Currently, we only support a single package for international
            shipments
            <br />
            If you need multiple packages, generate a label off-platform
          </div>
        )}
      </div>
    </div>
  );
};

type RatesSectionProps = GenerateLabelSectionProps & {
  isDisabled: boolean;
};
const RatesSection: React.FC<RatesSectionProps> = ({
  formik,
  fulfillment,
  rates
}) => {
  const formatDate = useFormatDate();
  const selectedRate = useMemo(() => {
    return formik.values.serviceCode;
  }, [formik.values.serviceCode]);

  if (!rates.length) {
    return null;
  }
  return (
    <div className="tw-mb-4 tw-flex tw-flex-1 tw-flex-col">
      <div className="tw-mt-12 tw-mb-4 tw-flex tw-flex-1 tw-text-lg">
        <div className="tw-flex-1">Rates</div>
        <div className="tw-flex-1 tw-text-right">
          {' '}
          Buyer Paid:{' '}
          <span className="tw-text-xl tw-font-bold">
            <FormattedNumber
              style="currency"
              value={fulfillment.fulfillmentTotal.amount}
              currency={fulfillment.fulfillmentTotal.currency}
            />
          </span>
        </div>
      </div>
      <div className="tw-flex tw-flex-1">
        <ol className="space-y-2 tw-grid tw-flex-1 tw-grid-cols-3 tw-gap-4">
          {rates.map((rate, index) => {
            const isSelectedRate = selectedRate === rate.serviceCode;
            return (
              <li key={rate.name}>
                <button
                  className={classNames(
                    'tw-focus:outline-none tw-focus:border-focus-outline tw-group tw-flex tw-h-full tw-w-full tw-items-center tw-border tw-px-6 tw-py-4 tw-transition hover:tw-border-primary-400',
                    {
                      'tw-border-primary-400': isSelectedRate
                    }
                  )}
                  onClick={async () => {
                    formik.setFieldValue('carrierId', rate.carrierId);
                    formik.setFieldValue('serviceCode', rate.serviceCode);
                  }}
                >
                  <div
                    className={classNames('tw-mr-4', {
                      'tw-text-primary-500': isSelectedRate,
                      'tw-group-hover:text-primary-300 tw-text-gray-300 tw-transition':
                        isSelectedRate
                    })}
                  >
                    <SimpleIcon
                      className="tw-w-4"
                      iconName={isSelectedRate ? 'check-circle' : ''}
                    />
                  </div>
                  <div className="tw-flex-1 tw-text-left tw-font-medium">
                    {rate.description || rate.name} <br />
                    {rate.estimatedDeliveryDate && (
                      <div className="tw-text-sm tw-text-gray-500">
                        {formatDate(rate.estimatedDeliveryDate, {
                          weekday: 'long',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          timeZone: 'UTC'
                        })}
                      </div>
                    )}
                  </div>
                  <div className="tw-ml-2 tw-flex tw-justify-end tw-font-medium">
                    <FormattedNumber
                      style="currency"
                      value={rate.fulfillmentPriceCalculated.amount}
                      currency={rate.fulfillmentPriceCalculated.currency}
                    />
                  </div>
                </button>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};
