import messages from './ShipmentUtils.messages';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { RevMedShipment } from '../../../custom/types/FulfillmentTypes';

export function getTrackableCarriers(formatMessage) {
  return [
    {
      label: formatMessage(messages.carriers.ups),
      value: 'ups'
    },
    {
      label: formatMessage(messages.carriers.fedex),
      value: 'fedex'
    },
    {
      label: formatMessage(messages.carriers.usps),
      value: 'usps'
    },
    {
      label: formatMessage(messages.carriers.dhlExpress),
      value: 'dhl_express'
    }
  ];
}

export function getAllShipments(
  fulfillment: IOrderFulfillment
): RevMedShipment[] {
  const shipments = [] as RevMedShipment[];
  if (fulfillment?.shipment) {
    shipments.push(fulfillment.shipment as RevMedShipment);
  }
  if (fulfillment?.additionalShipments) {
    fulfillment.additionalShipments.forEach(shipment => {
      shipments.push(shipment as RevMedShipment);
    });
  }
  return shipments;
}
