import IconButton from '@broadleaf/admin-components/dist/common/components/IconButton';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

export type TabbedSectionsProps = {
  tabs: { label: string; component: React.ReactNode }[];
  selectedTab?: string;
  setSelectedTab: (tab: string) => void;
};

export const TabbedSections = (props: TabbedSectionsProps) => {
  const { tabs, setSelectedTab, selectedTab } = props;
  // set the first time to the default if none are selected
  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(tabs[0].label);
    }
  }, [setSelectedTab, selectedTab, tabs]);
  return (
    <div className="tw-flex tw-flex-1 tw-flex-col">
      <ul className="tw-border-b-1 tw-mb-0 tw-flex tw-w-full tw-flex-wrap tw-border-solid tw-border-gray-200 tw-py-0">
        {tabs.map((tab, index) => (
          <li key={tab.label} className="tw-cursor-pointer tw-text-lg">
            <button
              className={cx(
                'tw-focus:outline-none tw-block tw-cursor-pointer tw-border-b-2 tw-bg-transparent tw-px-4 tw-py-2 tw-font-medium tw-outline-none tw-transition-colors',
                {
                  'tw-border-b-2 tw-border-solid tw-border-gray-800 tw-text-gray-800':
                    selectedTab === tab.label,
                  'tw-hover:text-gray-500 tw-border-transparent tw-text-gray-400 hover:tw-border-gray-300 ':
                    selectedTab !== tab.label
                }
              )}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                selectedTab !== tab.label && setSelectedTab(tab.label);
              }}
              type="button"
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      {tabs.map(tab => (
        <div key={tab.label} className="tw-flex tw-flex-1">
          {selectedTab === tab.label && tab.component}
        </div>
      ))}
    </div>
  );
};
