/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { includes } from 'lodash';
import FulfillmentAction from '@broadleaf/admin-components/dist/oms/components/FulfillmentAction';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import {
  IMetadata,
  IMetadataAction
} from '@broadleaf/admin-components/dist/types/metadata';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import ManageShipmentsModal from '../../ManageShipmentsModal';
import log from '@broadleaf/admin-components/dist/common/utils/log';

const logger = log.getLogger(
  'oms.components.FulfillmentView.action-components.ManageShipments'
);

export interface StatusChangeProps {
  actionDefinition: IMetadataAction;
  dispatch: Function;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
}

const ManageShipments: React.FC<StatusChangeProps> = ({
  actionDefinition,
  dispatch,
  metadata,
  state
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contextParams = useContextParams(metadata, state);
  const { attributes = {}, label } = actionDefinition;
  const {
    allowedStatuses = [],
    fulfillmentTypeAllowedStatuses = {},
    fulfillmentType = ''
  } = attributes as Record<string, any>;
  const handleClose = () => setIsOpen(false);
  const [loading, setLoading] = useState<boolean>(false);
  const allAllowedStatuses = allowedStatuses.concat(
    fulfillmentTypeAllowedStatuses[state.data.type]
  );

  if (
    !includes(allowedStatuses, state.data.status) ||
    state.data.type !== 'SHIP' ||
    state.data.fulfillmentOption.name === 'REVMED_DROPOFF'
  ) {
    return null;
  }

  return (
    <>
      <FulfillmentAction
        color="yellow"
        disabled={state.isFetching || loading}
        onClick={() => setIsOpen(true)}
      >
        Manage Shipments (Beta)
      </FulfillmentAction>

      {isOpen && (
        <ManageShipmentsModal
          metadata={actionDefinition}
          fulfillment={state.data}
          dispatch={dispatch}
          state={state}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ManageShipments;
