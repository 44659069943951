import { Field as FormikField, FieldAttributes } from 'formik';
import { IFormik } from '@broadleaf/admin-components/dist/types/form';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { get } from 'lodash';
import { IMetadataFieldComponent } from '@broadleaf/admin-components/dist/types/metadata';
import cx from 'classnames';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';
import React from 'react';

export type InputFieldProps = FieldAttributes<any> & {
  name: string;
  className?: string;
  inputClassName?: string;
  formik: IFormik;
  isDisabled?: boolean;
  min?: number;
  max?: number;
  showMin?: boolean;
  showMax?: boolean;
  tooltip?: string;
};
export const InputField = ({
  className = '',
  inputClassName = '',
  formik,
  name,
  isDisabled,
  label,
  type = 'text',
  tooltip,
  hint,
  ...props
}: InputFieldProps) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: label,
    tooltip,
    hint
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-1 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            {...props}
            className={cx(
              inputClassName,
              'tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1'
            )}
            name={name}
            disabled={formik.isSubmitting || isDisabled}
            showError={showError}
            type={type}
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const Input = ({
  field,
  min,
  max,
  showMin = false,
  showMax = false,
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  const formatNumber = useFormatNumber();
  return (
    <div className="tw-flex tw-w-full tw-flex-1 tw-flex-row">
      <div className="tw-flex-1">
        <input
          {...field}
          {...props}
          className={cx(
            'tw-mr-1 tw-w-full tw-rounded tw-border tw-px-2 tw-py-1',
            {
              'tw-border-gray-300': !showError,
              'tw-border-red-600': showError
            }
          )}
        />
      </div>
      {showMin && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Min:{' '}
          {formatNumber(min, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
      {showMax && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Max:{' '}
          {formatNumber(max, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
    </div>
  );
};
