import { IMetadataEndpoint } from '@broadleaf/admin-components/dist/types/metadata';

export function getAddExternalShipmentEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/add-external-shipment'
  };
}

export function getRateShipmentEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/rate-shipment'
  };
}

export function getDefaultShipmentOptionsEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'GET',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/default-shipment-options'
  };
}

export function getPurchaseLabelEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/purchase-label'
  };
}

export function getUpdateShippingAddressEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/update-shipping-address'
  };
}

export function getVoidShipmentEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/void-shipment'
  };
}

export function getRemoveShipmentEndpoint(
  fulfillmentId: string
): IMetadataEndpoint {
  return {
    scope: 'ORDER_FULFILLMENT',
    type: 'POST',
    uri:
      '/order-operations/fulfillment-operations/' +
      fulfillmentId +
      '/remove-shipment'
  };
}
