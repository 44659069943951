/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field as FormikField } from 'formik';
import cx from 'classnames';
import { get, reduce } from 'lodash';
import * as yup from 'yup';

import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import SVG from '@broadleaf/admin-components/dist/common/components/SVG';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import {
  getRevMedSalesUsers,
  IOrderFulfillmentCommissions
} from '../../utils/RmFulfillmentUtils';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';
import ToggleSwitch from '@broadleaf/admin-components/dist/form/components/ToggleSwitch';

export interface UpdateFulfillmentCommissionsModalProps {
  metadata: IMetadata;
  fulfillment: IOrderFulfillment;
  fulfillmentCommissions: IOrderFulfillmentCommissions;
  onClose: () => void;
  onSubmit: Function;
  isSubmitting: boolean;
  title: string;
}

export const UpdateFulfillmentCommissionsModal: React.FC<UpdateFulfillmentCommissionsModalProps> = ({
  metadata,
  fulfillment,
  fulfillmentCommissions,
  onClose,
  onSubmit,
  isSubmitting = false,
  title
}) => {
  const initialValues = useInitialValues({
    fulfillment,
    fulfillmentCommissions
  });
  const validationSchema = useValidationSchema({
    fulfillment,
    fulfillmentCommissions,
    metadata
  });
  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        await onSubmit(values);
        formik.setSubmitting(false);
        clearFormikErrors(formik);
        onClose();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [onSubmit]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <SimpleModal
          /*// @ts-ignore */
          closeOnClickOutside={false}
          footer={
            <ModalFooter
              formik={formik}
              isSubmitting={isSubmitting}
              submitLabel={'Update'}
              //TODO determine if this is needed
              submitNote={''}
            />
          }
          isOpen
          onClose={onClose}
          size="xl"
          title={title}
        >
          <Fields
            formik={formik}
            fulfillment={fulfillment}
            metadata={metadata}
            isSubmitting={isSubmitting}
          />
        </SimpleModal>
      )}
    </Formik>
  );
};

function round(value: number, decimals: number) {
  return Math.round(value * 10 ** decimals) / 10 ** decimals;
}

const Fields = props => {
  const { formik, fulfillment, metadata, isSubmitting } = props;

  useEffect(() => {
    let totalPercentageSum = 0;
    let totalAmountSum = 0;
    for (const cli of formik.values.commissionLineItems) {
      totalAmountSum = totalAmountSum + (cli.amount || 0);
      totalPercentageSum = totalPercentageSum + (cli.percentage || 0);
    }
    formik.setFieldValue('totalPercentage', round(totalPercentageSum, 4));
    formik.setFieldValue('totalAmount', round(totalAmountSum, 2));
  }, [formik.values.commissionLineItems, formik.setFieldValue]);

  return (
    <div>
      <div className="tw-mb-3 tw-flex tw-flex-col">
        <div className="tw-flex-1 tw-text-lg">RevMed Commissions</div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <TotalAmountInput formik={formik} />
          <TotalPercentInput formik={formik} />
        </div>
        <div className="tw-mt-12 tw-mb-2 tw-flex-1 tw-text-lg">
          RevMed Fulfillment Settings
        </div>
        {formik.values.commissionLineItems.map((cli, index) => {
          return (
            <div className="tw-flex tw-flex-1 tw-flex-row">
              <CommissionLineItem
                formik={formik}
                index={index}
                fulfillment={fulfillment}
              />
            </div>
          );
        })}
        <button
          disabled={isSubmitting}
          className={classNames(
            'tw-text-md focus:tw-shadow-outline tw-m-auto tw-w-fit tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-py-2',
            {
              'tw-cursor-not-allowed': isSubmitting
            }
          )}
          style={{ opacity: isSubmitting ? '0.65' : '1' }}
          onClick={() => {
            formik.setFieldValue('commissionLineItems', [
              ...formik.values.commissionLineItems,
              {
                amount: 0,
                percentage: 0,
                type: 'BUYER'
              }
            ]);
          }}
        >
          Add Commission
        </button>
      </div>
    </div>
  );
};

const TotalAmountInput = ({ className = '', formik }) => {
  const formatMessage = useFormatMessage();
  const name = 'totalAmount';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Total Commission Amount',
    helpText: 'The amount of commission distributed for the order'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-500 tw-px-2 tw-py-1"
            min="0"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
            disabled={true}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const TotalPercentInput = ({ className = '', formik }) => {
  const formatMessage = useFormatMessage();
  const name = 'totalPercentage';
  const value = get(formik.values, name);
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Total Commission Percentage',
    helpText: 'The percentage of merchandise total for commissions'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-900 tw-px-2 tw-py-1"
            min="0"
            name={name}
            showError={showError}
            step=".0001"
            value={round(value * 100, 2)}
            type="number"
            subType="percentage"
            component={Input}
            disabled={true}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CommissionLineItem = ({ formik, index, fulfillment }) => {
  return (
    <>
      <CommissionLineItemType formik={formik} index={index} />
      <CommissionLineItemReason formik={formik} index={index} />
      <CommissionLineItemEmail formik={formik} index={index} />
      <CommissionLineItemAmount
        formik={formik}
        index={index}
        merchandiseTotal={fulfillment.merchandiseTotal}
      />
      <CommissionLineItemPercentage
        formik={formik}
        index={index}
        merchandiseTotal={fulfillment.merchandiseTotal}
      />

      <button
        onClick={() => {
          formik.setFieldValue(
            'commissionLineItems',
            formik.values.commissionLineItems.filter((cli, idx) => {
              return idx !== index;
            })
          );
        }}
      >
        <SVG
          className="tw-mx-auto tw-ml-2 tw-mt-5 tw-h-4 tw-w-4 tw-fill-current tw-text-red-500"
          name="close-solid"
        />
      </button>
    </>
  );
};

const CommissionLineItemType = ({ className = '', formik, index }) => {
  const formatMessage = useFormatMessage();
  const name = `commissionLineItems[${index}].type`;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const value = get(formik.values, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Type',
    helpText: 'Is commission for buyer or seller side'
  } as IMetadataFieldComponent;

  const typeOptions = [
    { value: 'BUYER', label: 'Buyer' },
    { value: 'SELLER', label: 'Seller' }
  ];

  useEffect(() => {
    if (!value) {
      formik.setFieldValue(name, 'BUYER');
    }
  }, [value, formik]);

  return (
    <div className="">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-2 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <ToggleSwitch
            name={name}
            className="tw-border-gray-500 tw-p-0"
            options={typeOptions}
            onBlur={formik.handleBlur}
            onChange={function onChange(value) {
              formik.setFieldValue(name, value);
            }}
            value={value}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CommissionLineItemReason = ({ className = '', formik, index }) => {
  const formatMessage = useFormatMessage();
  const name = `commissionLineItems[${index}].reason`;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Recipient Type',
    helpText: 'The reason for giving commission'
  } as IMetadataFieldComponent;

  const reasonOptions = [
    { value: 'SALES_REP', label: 'Sales Rep' },
    { value: 'SALES_MANAGER', label: 'Sales Manager' },
    { value: 'EXTERNAL', label: 'External' }
  ];
  return (
    <div className="tw-w-48">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-500 tw-px-2 tw-py-1"
            fieldName={name}
            showError={showError}
            formik={formik}
            options={reasonOptions}
            component={CreatableSelectInput}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CommissionLineItemAmount = ({
  className = '',
  formik,
  index,
  merchandiseTotal
}) => {
  const formatMessage = useFormatMessage();
  const name = `commissionLineItems[${index}].amount`;
  const percentageName = `commissionLineItems[${index}].percentage`;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Commission Amount',
    helpText: 'The amount of commission distributed'
  } as IMetadataFieldComponent;

  function recalculatePercentage(newAmount: number) {
    const newPercentage = newAmount / merchandiseTotal.amount;
    formik.setFieldValue(percentageName, round(newPercentage, 4));
  }

  return (
    <div className="">
      <div
        className={cx(
          className,
          'tw-flex tw-w-44 tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-rounded tw-border tw-border-gray-500 tw-px-2 tw-py-1"
            min="0.00"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
            onChange={e => {
              formik.setFieldValue(name, parseFloat(e.target.value || 0));
              const newAmount = round(e.target.value || 0, 2);
              recalculatePercentage(newAmount);
            }}
            onBlur={() => {
              const newAmount = round(get(formik.values, name) || 0, 2);
              formik.setFieldValue(name, newAmount);
            }}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CommissionLineItemPercentage = ({
  className = '',
  formik,
  index,
  merchandiseTotal
}) => {
  const formatMessage = useFormatMessage();
  const name = `commissionLineItems[${index}].percentage`;
  const amountName = `commissionLineItems[${index}].amount`;
  const value = get(formik.values, name) || 0;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Commission Percentage',
    helpText: 'The percentage of merchandise total for commission'
  } as IMetadataFieldComponent;

  function recalculateAmount(newPercentage: number) {
    const newAmount = round(newPercentage * merchandiseTotal.amount, 2);
    formik.setFieldValue(amountName, newAmount);
  }

  return (
    <div className="">
      <div
        className={cx(
          className,
          'tw-flex tw-w-44 tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-rounded tw-border tw-border-gray-500 tw-px-2 tw-py-1"
            max="100"
            min="0"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            subType="percentage"
            value={round(value * 100, 2)}
            component={Input}
            onChange={e => {
              const newValue = parseFloat(e.target.value || 0) / 100;
              formik.setFieldValue(name, newValue);
              const newPercentage = round(newValue, 4);
              recalculateAmount(newPercentage);
            }}
            onBlur={() => {
              const newPercentage = round(get(formik.values, name) || 0, 4);
              formik.setFieldValue(name, newPercentage);
            }}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CommissionLineItemEmail = ({ className = '', formik, index }) => {
  const formatMessage = useFormatMessage();
  const name = `commissionLineItems[${index}].email`;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Recipient Email',
    helpText: 'The email for the commission recipient'
  } as IMetadataFieldComponent;

  const emailOptions = getRevMedSalesUsers().map(user => {
    return { value: user.value, label: user.value };
  });

  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-500 tw-px-2 tw-py-1"
            showError={showError}
            fieldName={name}
            formik={formik}
            options={emailOptions}
            component={CreatableSelectInput}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const CreatableSelectInput = ({
  field,
  fieldName,
  options = [],
  formik,
  setFieldValue,
  placeholder = '',
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  const value = get(formik.values, fieldName) || '';

  const [createdOption, setCreatedOption] = useState({
    value: value,
    label: value
  });

  const allOptions = useMemo(() => {
    if (
      createdOption?.value &&
      options.findIndex(opt => opt.value === createdOption?.value) === -1
    ) {
      return [...options, createdOption];
    }
    return options;
  }, [options, createdOption]);

  const selectedOption = useMemo(() => {
    return (
      allOptions.find(opt => opt.value === value) || {
        value: value,
        label: value
      }
    );
  }, [allOptions, value]);

  return (
    <div className="tw-flex tw-w-full tw-flex-row">
      <div
        className={cx('tw-flex-1', {
          'tw-rounded tw-border tw-border-red-600': showError
        })}
      >
        <CreatableSelect
          {...field}
          {...props}
          className={cx('tw-mr-1 tw-w-full')}
          placeholder={placeholder}
          isMulti={false}
          autoFocus={false}
          isClearable={true}
          value={selectedOption}
          onChange={(option: any) => {
            formik.setFieldValue(fieldName, option?.value || '');
          }}
          onCreateOption={(inputValue: string) => {
            setCreatedOption({ value: inputValue, label: inputValue });
            formik.setFieldValue(fieldName, inputValue);
          }}
          formatCreateLabel={(inputValue: string) => `Use: "${inputValue}"`}
          options={allOptions}
        />
      </div>
    </div>
  );
};

const Input = ({
  field,
  min,
  max,
  subType = 'none',
  showMin = false,
  showMax = false,
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  const formatNumber = useFormatNumber();
  return (
    <div className="tw-flex tw-w-full tw-flex-row">
      <div className="">
        <input
          {...field}
          {...props}
          className={cx(
            'tw-mr-1 tw-w-full tw-rounded tw-border tw-px-2 tw-py-1.5',
            {
              'tw-border-gray-500': !showError,
              'tw-border-red-600': showError
            }
          )}
        />
        {subType === 'percentage' && (
          <span className="tw-relative tw-right-12">%</span>
        )}
      </div>
      {showMin && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Min:{' '}
          {formatNumber(min, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
      {showMax && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Max:{' '}
          {formatNumber(max, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
    </div>
  );
};

const ModalFooter = ({ formik, isSubmitting, submitLabel, submitNote }) => {
  return (
    <>
      {formik.isSubmitting ? (
        <Spinner className="tw-mr-2" />
      ) : (
        <FormikError className="tw-mr-2" formik={formik} />
      )}

      <span className="tw-text-sm tw-text-gray-700">{submitNote}</span>
      <button
        disabled={isSubmitting}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting
          }
        )}
        onClick={() => formik.submitForm()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};

function useInitialValues({ fulfillment, fulfillmentCommissions }) {
  return useMemo(() => {
    return {
      fulfillmentId: fulfillment.id,
      ...fulfillmentCommissions
    };
  }, [fulfillment, fulfillmentCommissions]);
}

function useValidationSchema({
  fulfillment,
  fulfillmentCommissions,
  metadata
}) {
  const formatMessage = useFormatMessage();

  return useMemo(() => {
    const validation = yup.object().shape({
      totalAmount: yup.number().required('Total Amount is required'),
      totalPercentage: yup.number().required('Total Percent is required'),
      commissionLineItems: yup.array().of(
        yup.object().shape({
          email: yup.string().email().required('Recipient required'),
          reason: yup.string().required('Recipient type required'),
          type: yup.string().required('Required type required'),
          amount: yup.number().required('Amount required'),
          percentage: yup.number().required('Percentage required')
        })
      )
    });

    return validation;
  }, []);
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default UpdateFulfillmentCommissionsModal;
