/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import classNames from 'classnames';
import { startCase } from 'lodash';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';

import {
  useFormatDate,
  useFormatNumber
} from '@broadleaf/admin-components/dist/common';

import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import {
  getRemoveShipmentEndpoint,
  getVoidShipmentEndpoint
} from '../util/FulfillmentOperationUtils';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import { getAllShipments } from '../util/ShipmentUtils';
import { AddTrackingNumberForm } from './AddTrackingNumberForm';
import { UpdateShippingAddressForm } from './UpdateShippingAddressForm';
import { GenerateLabelForm } from './GenerateLabelForm';
import { TabbedSections } from '../components/TabbedSections';
import { RevMedShipment } from '../../../custom/types/FulfillmentTypes';

export const ManageShipmentsModal: React.FC<
  ManageShipmentsModalProps
> = props => {
  const { metadata, fulfillment, onClose } = props;
  return (
    <SimpleModal
      /*// @ts-ignore */
      closeOnClickOutside={false}
      isOpen
      onClose={onClose}
      size="xl"
      className="tw-"
      title={<>Manage Shipments (Beta)</>}
    >
      <ModalBody {...props} />
    </SimpleModal>
  );
};

export type ManageShipmentsModalProps = ManageShipmentsProps;

export interface ManageShipmentsProps {
  fulfillment: IOrderFulfillment;
  metadata: IMetadata;
  state: IFulfillmentViewLocalState;
  dispatch: Function;
  onClose: () => void;
}

export type ManageShipmentsSectionProps = ManageShipmentsProps & {
  onComplete: () => void;
};

const ModalBody: React.FC<ManageShipmentsProps> = props => {
  const { fulfillment, state, dispatch, metadata } = props;
  const formatDate = useFormatDate();
  const formatNumber = useFormatNumber();

  const shipments = getAllShipments(fulfillment);
  const [selectedTab, setSelectedTab] = useState<string | null>(
    'Generate Label'
  );

  const onComplete = useCallback(() => {
    setSelectedTab('Generate Label');
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Generate Label',
        component: <GenerateLabelForm {...props} onComplete={onComplete} />
      },
      {
        label: 'Add Tracking Number',
        component: <AddTrackingNumberForm {...props} onComplete={onComplete} />
      },
      {
        label: 'Update Shipping Address',
        component: (
          <UpdateShippingAddressForm {...props} onComplete={onComplete} />
        )
      }
    ];
  }, [props, onComplete]);

  return (
    <div>
      {/* Details about the shipments*/}
      <div className="tw-my-3 tw-flex tw-flex-1 tw-flex-col">
        <div className="tw-flex-1 tw-text-lg">
          Shipments ({shipments.length})
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <table className="tw-w-full tw-divide-y tw-divide-gray-200">
            <thead>
              <tr>
                <th className="tw-px-4 tw-py-2">Service</th>
                <th className="tw-px-4 tw-py-2">Tracking Number</th>
                <th className="tw-px-4 tw-py-2">Status</th>
                <th className="tw-px-4 tw-py-2">Cost</th>
                <th className="tw-px-4 tw-py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {shipments.map((shipment: RevMedShipment, index) => (
                <tr
                  key={index}
                  className={cx(
                    'hover:tw-bg-gray-200 ' +
                      (index % 2 === 0 ? 'tw-bg-gray-100' : 'tw-bg-white')
                  )}
                >
                  <td className="tw-px-4 tw-py-1">
                    {shipment?.shipmentOptions?.serviceCode
                      ? startCase(shipment?.shipmentOptions?.serviceCode)
                      : startCase(shipment?.carrierCode)}
                    {}
                  </td>
                  <td className="tw-px-4 tw-py-1">
                    <a
                      className="tw-inline-block tw-text-link-600 hover:tw-text-link-700"
                      href={shipment.trackingUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {shipment.trackingNumber}
                    </a>
                  </td>
                  <td className="tw-px-4 tw-py-1">
                    {startCase(shipment.shipmentStatus)}
                  </td>
                  <td className="tw-px-4 tw-py-1">
                    {shipment.labelCost?.amount && (
                      <span>
                        {formatNumber(shipment.labelCost.amount, {
                          style: 'currency',
                          currency: shipment.labelCost.currency
                        })}
                      </span>
                    )}
                  </td>
                  <td className="tw-w-18 tw-flex tw-px-4 tw-py-1">
                    <RemoveButton
                      shipment={shipment}
                      state={state}
                      metadata={metadata}
                      dispatch={dispatch}
                    />
                    {(shipment.labelPdfUrl || shipment.labelPngUrl) && (
                      <a
                        className="tw-text-md tw-ml-2 tw-flex tw-items-center tw-whitespace-nowrap tw-rounded tw-border tw-bg-primary-500 tw-px-1 tw-py-1 tw-text-center tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 hover:tw-text-primary-100 focus:tw-outline-none"
                        href={shipment.labelPdfUrl || shipment.labelPngUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="tw--mr-6 tw-flex-1">Label</div>
                        <div className="tw-flex-0 tw-ml-2 tw-w-4"> </div>
                      </a>
                    )}
                    {shipment.customsFormPdfUrl && (
                      <a
                        className="tw-text-md tw-ml-2 tw-flex tw-items-center tw-whitespace-nowrap tw-rounded tw-border tw-bg-primary-500 tw-px-1 tw-py-1 tw-text-center tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 hover:tw-text-primary-100 focus:tw-outline-none"
                        href={shipment.customsFormPdfUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="tw--mr-6 tw-flex-1">Customs</div>
                        <div className="tw-flex-0 tw-ml-2 tw-w-4"> </div>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <TabbedSections
        tabs={tabs}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </div>
  );
};

const RemoveButton = ({ shipment, state, metadata, dispatch }) => {
  const contextParams = useContextParams(metadata);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleRemove = useEventCallback(
    async shipment => {
      setIsSubmitting(true);
      try {
        let endpoint = getRemoveShipmentEndpoint(state.data.id);
        if (shipment.externalId) {
          endpoint = getVoidShipmentEndpoint(state.data.id);
        }

        const { data: response } = await request(
          {
            method: 'post',
            data: {
              externalId: shipment.externalId,
              trackingNumber: shipment.trackingNumber
            },
            ...endpoint
          },
          contextParams
        );

        setIsSubmitting(false);

        setTimeout(() => {
          dispatch(queueFetch());
        }, 100);
      } catch (error) {
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    },
    [contextParams, state, dispatch]
  );
  const voidableStatuses = useMemo(() => ['UNKNOWN', 'LABEL_CREATED'], []);
  if (
    shipment.externalId &&
    !voidableStatuses.includes(shipment.shipmentStatus)
  ) {
    return null;
  }

  return (
    <>
      <button
        disabled={isSubmitting || state.isFetching}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-flex tw-flex-1 tw-items-center tw-whitespace-nowrap tw-rounded tw-border tw-bg-danger-500 tw-px-1 tw-py-1 tw-text-center tw-font-semibold tw-text-danger-100 tw-shadow hover:tw-bg-danger-600 focus:tw-outline-none',
          {
            'tw-cursor-not-allowed': isSubmitting || state.isFetching
          }
        )}
        onClick={() => handleRemove(shipment)}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        <div className="tw--mr-6 tw-flex-1">
          {shipment.externalId ? 'Void' : 'Remove'}
        </div>
        {isSubmitting ? (
          <Spinner
            size="md"
            className="tw-flex-0 tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        ) : (
          <div className="tw-flex-0 tw-ml-2 tw-w-4"> </div>
        )}
      </button>
    </>
  );
};

export default ManageShipmentsModal;
