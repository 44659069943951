import React, { useMemo } from 'react';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { getAddExternalShipmentEndpoint } from '../util/FulfillmentOperationUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import { getTrackableCarriers } from '../util/ShipmentUtils';
import { SelectField } from '../../../custom/common/form/SelectField';
import { InputField } from '../../../custom/common/form/InputField';
import { SubmitButton } from '../../../custom/common/form/SubmitButton';
import { ManageShipmentsSectionProps } from './ManageShipmentsModal';

export const AddTrackingNumberForm: React.FC<
  ManageShipmentsSectionProps
> = props => {
  const { metadata, fulfillment, dispatch, state, onComplete } = props;
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();

  const contextParams = useContextParams(metadata);
  const initialValues = useMemo(() => {
    return {
      carrierCode: '',
      trackingNumber: ''
    };
  }, []);
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      carrierCode: yup.string().required(),
      trackingNumber: yup.string().required()
    });
  }, []);
  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        const endpoint = getAddExternalShipmentEndpoint(props.state.data.id);

        const { data: response } = await request(
          { method: 'post', data: values, ...endpoint },
          contextParams
        );

        formik.setSubmitting(false);
        clearFormikErrors(formik);

        setTimeout(() => {
          dispatch(queueFetch());
        }, 100);
        formik.resetForm();
        onComplete();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [contextParams, props.state, props.dispatch, props.onClose]
  );
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <AddTrackingNumberSection
            formik={formik}
            fulfillment={fulfillment}
            state={state}
          />
        )}
      </Formik>
    </>
  );
};

export type AddTrackingNumberSectionProps = {
  formik: any;
  fulfillment: IOrderFulfillment;
  state: IFulfillmentViewLocalState;
};
export const AddTrackingNumberSection: React.FC<
  AddTrackingNumberSectionProps
> = ({ formik, fulfillment, state }) => {
  const checkAccess = UserAccess.useCheckAccess();
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  const hasAccess = checkAccess('ORDER_FULFILLMENT', 'UPDATE');

  const isDisabled = useMemo(() => {
    return !hasAccess || formik.isSubmitting || state.isFetching;
  }, [hasAccess, formik.isSubmitting, state.isFetching]);

  const options = getTrackableCarriers(formatMessage);
  return (
    <>
      <div className="tw-flex tw-flex-1 tw-flex-col tw-border-b tw-border-gray-500">
        <div className="tw-flex tw-flex-1">
          <div className="tw-flex-1">
            <SelectField
              formik={formik}
              label="Carrier"
              name="carrierCode"
              placeholder="Choose a Carrier"
              options={options}
              isDisabled={isDisabled}
            />
          </div>
          <div className="tw-flex-1">
            <InputField
              formik={formik}
              name={`trackingNumber`}
              isDisabled={isDisabled}
              label="Tracking Number"
            />
          </div>
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row">
          <div className="tw-flex-1"></div>
          <div className="tw-flex tw-flex-1 tw-flex-row">
            <div className="tw-flex-0 tw-ml-auto tw-h-16">
              <SubmitButton
                formik={formik}
                isSubmitting={formik.isSubmitting}
                isDisabled={isDisabled}
                submitLabel="Add Shipment"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
