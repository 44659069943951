/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { Field as FormikField, Formik } from 'formik';
import cx from 'classnames';
import classNames from 'classnames';
import { find, get } from 'lodash';
import * as yup from 'yup';

import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import {
  IConsolidatedOrder,
  IInvoiceSummary
} from '../../../utils/RmFulfillmentUtils';
import {
  useFormatDate,
  useFormatNumber
} from '@broadleaf/admin-components/dist/common';
import {
  getApplyInvoiceSettingsEndpoint,
  getApplyRevMedCreditEndpoint
} from '../../utils/InvoicesOperationUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import type { ICollectionStateHook } from '@broadleaf/admin-components/dist/types/collection';
import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import Link from '@broadleaf/admin-components/dist/common/elements/Link';
import { getInvoiceStatusOptions } from '../../utils/InvoicesGridUtils';

export const InvoiceDetailsModal: React.FC<
  InvoiceDetailsModalProps
> = props => {
  const { metadata, consolidatedOrder, onClose } = props;
  return (
    <SimpleModal
      /*// @ts-ignore */
      closeOnClickOutside={false}
      isOpen
      onClose={onClose}
      size="lg"
      title={
        <>Invoice Details {consolidatedOrder.invoiceSummary.invoiceNumber}</>
      }
    >
      <ModalBody {...props} />
    </SimpleModal>
  );
};

export type InvoiceDetailsModalProps = InvoiceDetailsProps;

interface InvoiceDetailsProps {
  consolidatedOrder: IConsolidatedOrder;
  metadata: IMetadata;
  collectionState: ICollectionStateHook;
  onClose: () => void;
}

const ModalBody: React.FC<InvoiceDetailsProps> = props => {
  const { consolidatedOrder } = props;
  const formatDate = useFormatDate();
  const formatNumber = useFormatNumber();
  const invoice = consolidatedOrder.invoiceSummary;
  return (
    <div>
      <div className="tw-mb-2 tw-grid tw-grid-cols-6 tw-gap-2">
        <div className="tw-flex-1">Order Number:</div>
        <div className="tw-col-span-2 tw-flex-1">{invoice.orderNumber}</div>

        <div className="tw-flex-1">Invoice Status:</div>
        <div className="tw-col-span-2 tw-flex-1 tw-text-right">
          {invoice.invoiceStatus}
          {invoice.invoiceStatusReason && (
            <div className="tw-flex">
              <div className="tw-col-span-2 tw-flex-1 tw-text-right">
                Reason: {invoice.invoiceStatusReason}
              </div>
            </div>
          )}
        </div>
        <div className="tw-flex-1">Order Date:</div>
        <div className="tw-col-span-2 tw-flex-1">
          {formatDate(invoice.orderSubmitDate, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'UTC'
          })}
        </div>
        <div className="tw-flex-1">Invoice Total:</div>
        <div className="tw-col-span-2 tw-flex-1 tw-text-right">
          {formatNumber(invoice.preCreditTotal.amount, {
            style: 'currency',
            currency: invoice.preCreditTotal.currency
          })}
        </div>

        <div className="tw-flex-1">Ship To:</div>
        <div className="tw-col-span-2 tw-flex-1">
          {invoice.shipToOrganization}
          <br />
          {invoice.shipToAddressLine1}
          {invoice.shipToAddressLine2 && (
            <>
              <br />
              {invoice.shipToAddressLine2}
            </>
          )}
          <br />
          {invoice.shipToCity}, {invoice.shipToStateProvinceRegion}{' '}
          {invoice.shipToPostalCode}
        </div>
        <div className="tw-flex-1">
          Bill To{' '}
          {consolidatedOrder.invoiceCreditAccount && (
            <>
              (
              <Link
                to={`/customer-accounts/${consolidatedOrder.invoiceCreditAccount.owningUserRef}/store-credit-account/INVOICE_CREDIT?form=invoiceCreditForm`}
                className="tw-inline-block tw-text-xs tw-text-link-600 hover:tw-text-link-700"
              >
                View
              </Link>
              )
            </>
          )}
          :
        </div>
        <div className="tw-col-span-2 tw-flex-1">
          {invoice.billToOrganization}
          <br />
          {invoice.billToAddressLine1}
          {invoice.billToAddressLine2 && (
            <>
              <br />
              {invoice.billToAddressLine2}
            </>
          )}
          <br />
          {invoice.billToCity}, {invoice.billToStateProvinceRegion}{' '}
          {invoice.billToPostalCode}
        </div>

        {invoice.remitraExportId && (
          <>
            <div className="tw-flex-1">Exported Date:</div>
            <div className="tw-col-span-2 tw-flex-1">
              {formatDate(invoice.remitraExportTime, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC'
              })}
            </div>
            <div className="tw-flex-1">Export File:</div>
            <div className="tw-col-span-2 tw-flex-1">
              {invoice.remitraExportName}
            </div>
          </>
        )}
      </div>
      <InvoiceSettingsForm {...props} />

      {/* Details about the fulfillments*/}
      <div className="tw-my-6 tw-flex tw-flex-1 tw-flex-col">
        <div className="tw-flex-1 tw-text-lg">
          Fulfillments ({consolidatedOrder.fulfillments.length})
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <table className="tw-w-full tw-divide-y tw-divide-gray-200">
            <thead>
              <tr>
                <th className="tw-px-4 tw-py-2">Fulfillment Number</th>
                <th className="tw-px-4 tw-py-2">Status</th>
                <th className="tw-px-4 tw-py-2 tw-text-right">Grand Total</th>
                <th className="tw-px-4 tw-py-2">Seller</th>
              </tr>
            </thead>
            <tbody>
              {consolidatedOrder.fulfillments.map((fulfillment, index) => (
                <tr
                  key={index}
                  className={cx(
                    'hover:tw-bg-gray-200 ' +
                      (index % 2 === 0 ? 'tw-bg-gray-100' : 'tw-bg-white')
                  )}
                >
                  <td className="tw-px-4 tw-py-1">
                    <Link
                      to={'/fulfillments/' + fulfillment.id}
                      className="tw-inline-block tw-text-link-600 hover:tw-text-link-700"
                    >
                      {fulfillment.orderFulfillmentNumber}
                    </Link>
                  </td>
                  <td className="tw-px-4 tw-py-1">{fulfillment.status}</td>
                  <td className="tw-px-4 tw-py-1 tw-text-right">
                    {formatNumber(fulfillment.grandTotal.amount, {
                      style: 'currency',
                      currency: fulfillment.grandTotal.currency
                    })}
                  </td>
                  <td className="tw-px-4 tw-py-1">
                    {fulfillment.fulfillmentItems[0].attributes.vendor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/*TODO add more info to the summary*/}
      <ApplyRevMedCreditForm {...props} />
    </div>
  );
};

const InvoiceSettingsForm: React.FC<InvoiceDetailsProps> = props => {
  const { metadata, consolidatedOrder } = props;
  const formatMessage = useFormatMessage();
  const invoice = consolidatedOrder.invoiceSummary;
  const formatNumber = useFormatNumber();

  const contextParams = useContextParams(metadata);
  const initialValues = useMemo(() => {
    return {
      invoiceId: invoice.invoiceId,
      poNumber: invoice.poNumber,
      resetExportStatus: false,
      status: null,
      statusReason: ''
    };
  }, [invoice]);
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      invoiceId: yup.string(),
      poNumber: yup.string().max(22, 'PO Number max length is 22 characters'),
      resetExportStatus: yup.boolean(),
      status: yup.string().nullable(),
      statusReason: yup.string()
    });
  }, []);

  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        const endpoint = getApplyInvoiceSettingsEndpoint(values.invoiceId);

        const { data: exportData } = await request(
          { method: 'post', data: values, ...endpoint },
          contextParams
        );

        formik.setSubmitting(false);
        clearFormikErrors(formik);

        setTimeout(() => {
          props.collectionState.handleRead();
          props.collectionState.handleClearAllSelections();
        }, 500);
        props.onClose();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [contextParams, props.collectionState, props.onClose]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <InvoiceSettingsSection
          formik={formik}
          consolidatedOrder={consolidatedOrder}
          invoice={invoice}
        />
      )}
    </Formik>
  );
};

type InvoiceSettingsSectionProps = {
  formik: any;
  consolidatedOrder: IConsolidatedOrder;
  invoice: IInvoiceSummary;
};
const InvoiceSettingsSection: React.FC<InvoiceSettingsSectionProps> = ({
  formik,
  consolidatedOrder,
  invoice
}) => {
  const checkAccess = UserAccess.useCheckAccess();
  const formatNumber = useFormatNumber();
  const hasAccess = checkAccess('INVOICE', 'UPDATE');

  const isDisabled = useMemo(() => {
    return (
      !hasAccess ||
      ['CANCELLED', 'INVOICE_PAID'].includes(invoice.invoiceStatus)
    );
  }, [hasAccess, invoice]);
  return (
    <>
      <div className="tw-flex-1 tw-text-lg">Invoice Settings</div>

      <div className="tw-flex tw-flex-1 tw-flex-row">
        <div className="tw-flex-1">
          <PONumberInput
            formik={formik}
            name={`poNumber`}
            isDisabled={isDisabled}
          />
        </div>
        <div className="tw-flex-1">
          <InvoiceStatus
            formik={formik}
            name="status"
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-1 tw-flex-row">
        <div className="tw-flex-1"></div>
        <div className="tw-flex-1">
          <StatusReasonInput
            formik={formik}
            name="statusReason"
            isDisabled={isDisabled || !formik.values.status}
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-1 tw-flex-row">
        <div className="tw-flex-1"></div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <div className="tw-flex-0 tw-ml-auto tw-h-16 tw-pt-8">
            <SubmitButton
              formik={formik}
              isSubmitting={formik.isSubmitting}
              isDisabled={isDisabled}
              submitLabel="Apply Settings"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ApplyRevMedCreditForm: React.FC<InvoiceDetailsProps> = props => {
  const { metadata, consolidatedOrder } = props;
  const formatMessage = useFormatMessage();
  const invoice = consolidatedOrder.invoiceSummary;
  const availableRevMedCredit =
    consolidatedOrder.revmedCreditAccount?.balance?.amount;
  const formatNumber = useFormatNumber();
  const maxRevMedCreditAmount = useMemo(() => {
    return availableRevMedCredit &&
      availableRevMedCredit > invoice.grandTotal.amount
      ? invoice.grandTotal.amount
      : availableRevMedCredit || 0;
  }, [availableRevMedCredit, invoice]);

  const contextParams = useContextParams(metadata);
  const initialValues = useMemo(() => {
    return {
      invoiceId: invoice.invoiceId,
      revMedCreditAmount: {
        amount: maxRevMedCreditAmount,
        currency: invoice.grandTotal.currency
      },
      reasonCode: 'MANUAL_CREDIT',
      reasonNote: 'Manual RevMed Credit'
    };
  }, [invoice, maxRevMedCreditAmount]);
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      invoiceId: yup.string(),
      revMedCreditAmount: yup.object().shape({
        amount: yup.number().required().min(0).max(maxRevMedCreditAmount),
        currency: yup.string().required()
      }),
      reasonCode: yup.string().required(),
      reasonNote: yup.string().required()
    });
  }, [maxRevMedCreditAmount]);

  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        const endpoint = getApplyRevMedCreditEndpoint(values.invoiceId);

        const { data: exportData } = await request(
          { method: 'post', data: values, ...endpoint },
          contextParams
        );

        formik.setSubmitting(false);
        clearFormikErrors(formik);

        setTimeout(() => {
          props.collectionState.handleRead();
          props.collectionState.handleClearAllSelections();
        }, 500);
        props.onClose();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [contextParams, props.collectionState, props.onClose]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <ApplyRevMedCreditSection
          formik={formik}
          maxRevMedCreditAmount={maxRevMedCreditAmount}
          consolidatedOrder={consolidatedOrder}
          invoice={invoice}
        />
      )}
    </Formik>
  );
};

type ApplyRevMedCreditSectionProps = {
  formik: any;
  maxRevMedCreditAmount: number;
  consolidatedOrder: IConsolidatedOrder;
  invoice: IInvoiceSummary;
};
const ApplyRevMedCreditSection: React.FC<ApplyRevMedCreditSectionProps> = ({
  formik,
  maxRevMedCreditAmount,
  consolidatedOrder,
  invoice
}) => {
  const checkAccess = UserAccess.useCheckAccess();
  const formatNumber = useFormatNumber();
  const hasAccess = checkAccess('INVOICE', 'UPDATE');

  const isDisabled = useMemo(() => {
    return (
      !hasAccess ||
      maxRevMedCreditAmount <= 0 ||
      // we currently only allow applying credit to invoices in these statuses
      ![
        'ORDER_PROCESSING',
        'CREDIT_PENDING',
        'HOLD_FOR_REVIEW',
        'REQUIRES_PO',
        'READY_TO_SUBMIT'
      ].includes(invoice.invoiceStatus)
    );
  }, [hasAccess, maxRevMedCreditAmount, invoice]);
  return (
    <>
      <div className="tw-flex-1 tw-text-lg">
        RevMed Credit Summary
        {consolidatedOrder.revmedCreditAccount && (
          <Link
            to={`/customer-accounts/${consolidatedOrder.revmedCreditAccount.owningUserRef}/store-credit-account/REVMED_CREDIT`}
            className="tw-ml-2 tw-inline-block tw-text-xs tw-text-link-600 hover:tw-text-link-700"
          >
            View Details
          </Link>
        )}
      </div>
      <div className="tw-flex tw-flex-1 tw-flex-row">
        <table className="tw-w-full tw-divide-y tw-divide-gray-200">
          <thead>
            <tr>
              <th className="tw-px-4 tw-py-2 tw-text-right">Invoice Total</th>
              <th className="tw-px-4 tw-py-2 tw-text-right">Credit Applied</th>
              <th className="tw-px-4 tw-py-2 tw-text-right">Invoice Balance</th>
              <th className="tw-px-4 tw-py-2 tw-text-right">
                Credit Available
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={cx('tw-bg-gray-100 hover:tw-bg-gray-200')}>
              <td className="tw-px-4 tw-py-1 tw-text-right">
                {formatNumber(invoice.preCreditTotal.amount, {
                  style: 'currency',
                  currency: invoice.preCreditTotal.currency
                })}
              </td>
              <td className="tw-px-4 tw-py-1 tw-text-right">
                {formatNumber(invoice.revMedCreditAmount.amount, {
                  style: 'currency',
                  currency: invoice.revMedCreditAmount.currency
                })}
              </td>
              <td className="tw-px-4 tw-py-1 tw-text-right">
                {formatNumber(invoice.grandTotal.amount, {
                  style: 'currency',
                  currency: invoice.grandTotal.currency
                })}
              </td>
              <td className="tw-px-4 tw-py-1 tw-text-right">
                {formatNumber(
                  consolidatedOrder.revmedCreditAccount?.balance?.amount || 0,
                  {
                    style: 'currency',
                    currency:
                      consolidatedOrder.revmedCreditAccount?.balance
                        ?.currency || 'USD'
                  }
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="tw-flex tw-flex-1 tw-flex-row">
        <div className="tw-flex-1"></div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <RevMedCreditAmountInput
            formik={formik}
            max={maxRevMedCreditAmount}
            isDisabled={isDisabled}
          />
          <div className="tw-flex-0 tw-h-16 tw-pt-8">
            <SubmitButton
              formik={formik}
              isSubmitting={formik.isSubmitting}
              isDisabled={isDisabled}
              submitLabel="Apply Credit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PONumberInput = ({ className = '', formik, name, isDisabled }) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'PO Number'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            name={name}
            disabled={formik.isSubmitting || isDisabled}
            showError={showError}
            type="text"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const StatusReasonInput = ({ className = '', formik, name, isDisabled }) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Status Reason'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            name={name}
            disabled={formik.isSubmitting || isDisabled}
            showError={showError}
            type="text"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const RevMedCreditAmountInput = ({
  className = '',
  formik,
  max,
  isDisabled
}) => {
  const formatMessage = useFormatMessage();
  const name = 'revMedCreditAmount.amount';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'RevMed Credit Amount',
    helpText: 'Enter the RevMed Credit Amount you want to apply to this invoice'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            max={max}
            disabled={formik.isSubmitting || isDisabled}
            min="0"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const Input = ({
  field,
  min,
  max,
  showMin = false,
  showMax = false,
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  const formatNumber = useFormatNumber();
  return (
    <div className="tw-flex tw-w-full tw-flex-1 tw-flex-row">
      <div className="tw-flex-1">
        <input
          {...field}
          {...props}
          className={cx(
            'tw-mr-1 tw-w-full tw-rounded tw-border tw-px-2 tw-py-1',
            {
              'tw-border-gray-300': !showError,
              'tw-border-red-600': showError
            }
          )}
        />
      </div>
      {showMin && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Min:{' '}
          {formatNumber(min, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
      {showMax && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Max:{' '}
          {formatNumber(max, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
    </div>
  );
};

const InvoiceStatus = props => {
  const { formik, name, isDisabled, className = '' } = props;
  const formatMessage = useFormatMessage();
  const metadata = { name };
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);
  const optionsValue = get(formik.values, name, '');
  const options = getInvoiceStatusOptions(formatMessage);
  const selectedOption = useMemo(() => {
    return find(options, { value: optionsValue });
  }, [optionsValue, options]);

  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations fullWidth {...props} metadata={metadata}>
          <Select
            className={cx({
              'is-invalid': touched && !!error
            })}
            isClearable={false}
            isMulti={false}
            isDisabled={formik.isSubmitting || isDisabled}
            onChange={operator => {
              formik.setFieldValue(name, operator?.value);
            }}
            onBlur={formik.handleBlur}
            getOptionValue={option => option.value}
            options={options}
            name={name}
            placeholder={'Choose a status'}
            value={selectedOption}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const SubmitButton = ({
  formik,
  isSubmitting,
  submitLabel,
  isDisabled = false
}) => {
  return (
    <>
      {formik.isSubmitting ? (
        <Spinner className="tw-mr-2" />
      ) : (
        <FormikError className="tw-mr-2" formik={formik} />
      )}

      <button
        disabled={isSubmitting || isDisabled}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting || isDisabled
          }
        )}
        onClick={() => formik.submitForm()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default InvoiceDetailsModal;
