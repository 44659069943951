/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import CreateReturn from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/action-components/FulfillmentCreateReturn';
import PrintPackingSlip from './PrintPackingSlip';
import PrintShippingLabel from './PrintShippingLabel';
import StatusChange from './StatusChange';
import UpdateTracking from './UpdateTracking';
import UpdateFulfillmentSettings from './UpdateFulfillmentSettings';
import UpdateFulfillmentCommissions from './UpdateFulfillmentCommissions';
import ManageShipments from './ManageShipments';

export default [
  {
    placement: 'PRIMARY',
    type: 'STATUS_CHANGE',
    component: StatusChange
  },
  {
    placement: 'PRIMARY',
    type: 'CREATE_RETURN',
    component: CreateReturn
  },
  {
    placement: 'PRIMARY',
    type: 'PRINT_SHIPPING_LABEL',
    component: PrintShippingLabel
  },
  {
    placement: 'PRIMARY',
    type: 'UPDATE_TRACKING',
    component: UpdateTracking
  },
  {
    placement: 'PRIMARY',
    type: 'UPDATE_FULFILLMENT_SETTINGS',
    component: UpdateFulfillmentSettings
  },
  {
    placement: 'PRIMARY',
    type: 'UPDATE_FULFILLMENT_COMMISSIONS',
    component: UpdateFulfillmentCommissions
  },
  {
    placement: 'PRIMARY',
    type: 'MANAGE_SHIPMENTS',
    component: ManageShipments
  },
  {
    placement: 'PRIMARY',
    type: 'PRINT_PACKING_SLIP',
    component: PrintPackingSlip
  }
];

export type { FulfillmentCreateReturnProps } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/action-components/FulfillmentCreateReturn';
export type { PrintPackingSlipProps } from './PrintPackingSlip';
export type { PrintShippingLabelProps } from './PrintShippingLabel';
export type { StatusChangeProps } from './StatusChange';
export type { UpdateTrackingProps } from './UpdateTracking';
