import React, { useEffect, useMemo, useState } from 'react';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';
import useContextParams from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/hooks/useContextParams';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import { getUpdateShippingAddressEndpoint } from '../util/FulfillmentOperationUtils';
import { request } from '@broadleaf/admin-components/dist/metadata/utils/request';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';
import { queueFetch } from '@broadleaf/admin-components/dist/oms/hooks/useFufillmentState';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { IFulfillmentViewLocalState } from '@broadleaf/admin-components/dist/oms/components/FulfillmentView/fulfillment';
import { UserAccess } from '@broadleaf/admin-components/dist/authentication';
import { Option, SelectField } from '../../../custom/common/form/SelectField';
import { InputField } from '../../../custom/common/form/InputField';
import { SubmitButton } from '../../../custom/common/form/SubmitButton';
import {
  ManageShipmentsProps,
  ManageShipmentsSectionProps
} from './ManageShipmentsModal';
import cx from 'classnames';
import { CollapsableSection } from '../components/CollapsableSection';

import messages from './ManageShipmentsModal.messages';
import {
  SubdivisionMap,
  useFetchCountryData
} from '../../../custom/hooks/useFetchCountryData';
import { IMetadata } from '@broadleaf/admin-components/dist/types/metadata';

export const UpdateShippingAddressForm: React.FC<
  ManageShipmentsSectionProps
> = props => {
  const { metadata, fulfillment, dispatch, state, onComplete } = props;
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();

  const contextParams = useContextParams(metadata);
  const initialValues = useMemo(() => {
    return {
      ...fulfillment.address
    };
  }, [fulfillment.address]);
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      //TODO do we need validation here?
    });
  }, []);

  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        const endpoint = getUpdateShippingAddressEndpoint(props.state.data.id);

        const { data: response } = await request(
          { method: 'post', data: values, ...endpoint },
          contextParams
        );

        formik.setSubmitting(false);
        clearFormikErrors(formik);

        setTimeout(() => {
          dispatch(queueFetch());
        }, 100);
        formik.resetForm();
        onComplete();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [contextParams, props.state, props.dispatch, props.onClose]
  );
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <UpdateShippingAddressSection
            metadata={metadata}
            formik={formik}
            fulfillment={fulfillment}
            state={state}
          />
        )}
      </Formik>
    </>
  );
};

type UpdateShippingAddressSectionProps = {
  metadata: IMetadata;
  formik: any;
  fulfillment: IOrderFulfillment;
  state: IFulfillmentViewLocalState;
};
const UpdateShippingAddressSection: React.FC<
  UpdateShippingAddressSectionProps
> = ({ formik, fulfillment, state, metadata }) => {
  const checkAccess = UserAccess.useCheckAccess();
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  const hasAccess = checkAccess('ORDER_FULFILLMENT', 'UPDATE');
  const { setFieldValue, values } = formik;
  const isDisabled = useMemo(() => {
    return !hasAccess || formik.isSubmitting || state.isFetching;
  }, [hasAccess, formik.isSubmitting, state.isFetching]);

  const { countrySelectOptions, subdivisionSelectOptions } =
    useFetchCountryData(metadata);
  const [country, setCountry] = useState(null);
  const stateProvinceRegion = values['stateProvinceRegion'];

  const fieldCountry = values['country'];
  useEffect(() => {
    if (!country) {
      setCountry(fieldCountry || 'US');
    } else if (country !== fieldCountry) {
      setFieldValue('stateProvinceRegion', '');
    }
  }, [setFieldValue, country, fieldCountry]);

  const stateOptions = useMemo(() => {
    return [
      {
        label: 'Select State/Province/Region',
        value: ''
      },
      ...(subdivisionSelectOptions?.[country] || [])
    ];
  }, [country, formatMessage, subdivisionSelectOptions]);

  return (
    <>
      <div className="tw-flex tw-flex-1 tw-flex-col tw-border-b tw-border-gray-500">
        <div className={cx('flex flex-col gap-4')}>
          <InputField formik={formik} label="Name" name="fullName" required />
          <InputField
            formik={formik}
            label="Company Name"
            name="companyName"
            required
          />
          <InputField
            formik={formik}
            label="Address Line 1"
            name="addressLine1"
            required
          />
          <InputField
            formik={formik}
            label="Address Line 2"
            name="addressLine2"
          />
          <SelectField
            formik={formik}
            label="Country"
            name="country"
            options={countrySelectOptions}
            required
          />
          <div className="space-y-4 lg:flex lg:space-x-3 lg:space-y-0">
            <InputField
              formik={formik}
              className="flex-1"
              label="City"
              name="city"
              required
            />
            <SelectField
              formik={formik}
              className="flex-1"
              label="State/Province/Region"
              name="stateProvinceRegion"
              options={stateOptions}
              required
            />
            <InputField
              formik={formik}
              className="flex-1"
              label="Postal Code"
              name="postalCode"
              required
            />
          </div>
          <InputField
            formik={formik}
            label="Phone Number"
            name="phonePrimary.phoneNumber"
            required
            type="tel"
          />
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row">
          <div className="tw-mr-4 tw-flex-1 tw-text-right tw-text-sm tw-text-gray-500">
            Note: After updating the address, <br />
            you may want to void and re-print the shipping label
          </div>
          <div className="tw-flex-0 tw-flex tw-flex-row">
            <div className="tw-flex-0 tw-ml-auto tw-h-16">
              <SubmitButton
                formik={formik}
                isSubmitting={formik.isSubmitting}
                isDisabled={isDisabled}
                submitLabel="Update Address"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
