import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import classNames from 'classnames';
import React from 'react';

export const SubmitButton = ({
  formik,
  isSubmitting,
  submitLabel,
  isDisabled = false
}) => {
  return (
    <>
      {formik.isSubmitting ? (
        <Spinner className="tw-mr-2" />
      ) : (
        <FormikError className="tw-mr-2" formik={formik} />
      )}

      <button
        disabled={isSubmitting || isDisabled}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting || isDisabled
          }
        )}
        onClick={() => formik.submitForm()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};
