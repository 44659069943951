import { defineMessages } from 'react-intl';

const carriers = defineMessages({
  ups: {
    id: 'ShipmentUtils.carriers.ups',
    defaultMessage: 'UPS',
    description: ''
  },
  fedex: {
    id: 'ShipmentUtils.carriers.fedex',
    defaultMessage: 'FedEx',
    description: ''
  },
  usps: {
    id: 'ShipmentUtils.carriers.usps',
    defaultMessage: 'USPS',
    description: ''
  },
  dhlExpress: {
    id: 'ShipmentUtils.carriers.dhlExpress',
    defaultMessage: 'DHL Express',
    description: ''
  }
});

export default {
  carriers
};
