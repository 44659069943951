import { isString } from 'lodash';
import { Option } from '../common/form/SelectField';
import { ISOCountryAndStateProvinceRegion } from '../types/AddressTypes';
import { IMetadataEndpoint } from '@broadleaf/admin-components/dist/types/metadata';

export function getCountriesAndStateProvinceRegionsEndpoint(): IMetadataEndpoint {
  return {
    scope: 'CUSTOMER',
    type: 'GET',
    uri: '/customer/countries/all'
  };
}

export const USCountries = Object.freeze([
  {
    alpha2: 'US',
    name: 'United States',
    subdivisions: [
      {
        value: 'AL',
        isocode: 'US-AL',
        name: 'Alabama'
      },
      {
        value: 'AK',
        isocode: 'US-AK',
        name: 'Alaska'
      },
      {
        value: 'AZ',
        isocode: 'US-AZ',
        name: 'Arizona'
      },
      {
        value: 'AR',
        isocode: 'US-AR',
        name: 'Arkansas'
      },
      {
        value: 'CA',
        isocode: 'US-CA',
        name: 'California'
      },
      {
        value: 'CO',
        isocode: 'US-CO',
        name: 'Colorado'
      },
      {
        value: 'CT',
        isocode: 'US-CT',
        name: 'Connecticut'
      },
      {
        value: 'DE',
        isocode: 'US-DE',
        name: 'Delaware'
      },
      {
        value: 'FL',
        isocode: 'US-FL',
        name: 'Florida'
      },
      {
        value: 'GA',
        isocode: 'US-GA',
        name: 'Georgia'
      },
      {
        value: 'HI',
        isocode: 'US-HI',
        name: 'Hawaii'
      },
      {
        value: 'ID',
        isocode: 'US-ID',
        name: 'Idaho'
      },
      {
        value: 'IL',
        isocode: 'US-IL',
        name: 'Illinois'
      },
      {
        value: 'IN',
        isocode: 'US-IN',
        name: 'Indiana'
      },
      {
        value: 'IA',
        isocode: 'US-IA',
        name: 'Iowa'
      },
      {
        value: 'KS',
        isocode: 'US-KS',
        name: 'Kansas'
      },
      {
        value: 'KY',
        isocode: 'US-KY',
        name: 'Kentucky'
      },
      {
        value: 'LA',
        isocode: 'US-LA',
        name: 'Louisiana'
      },
      {
        value: 'ME',
        isocode: 'US-ME',
        name: 'Maine'
      },
      {
        value: 'MD',
        isocode: 'US-MD',
        name: 'Maryland'
      },
      {
        value: 'MA',
        isocode: 'US-MA',
        name: 'Massachusetts'
      },
      {
        value: 'MI',
        isocode: 'US-MI',
        name: 'Michigan'
      },
      {
        value: 'MN',
        isocode: 'US-MN',
        name: 'Minnesota'
      },
      {
        value: 'MS',
        isocode: 'US-MS',
        name: 'Mississippi'
      },
      {
        value: 'MO',
        isocode: 'US-MO',
        name: 'Missouri'
      },
      {
        value: 'MT',
        isocode: 'US-MT',
        name: 'Montana'
      },
      {
        value: 'NE',
        isocode: 'US-NE',
        name: 'Nebraska'
      },
      {
        value: 'NV',
        isocode: 'US-NV',
        name: 'Nevada'
      },
      {
        value: 'NH',
        isocode: 'US-NH',
        name: 'New Hampshire'
      },
      {
        value: 'NJ',
        isocode: 'US-NJ',
        name: 'New Jersey'
      },
      {
        value: 'NM',
        isocode: 'US-NM',
        name: 'New Mexico'
      },
      {
        value: 'NY',
        isocode: 'US-NY',
        name: 'New York'
      },
      {
        value: 'NC',
        isocode: 'US-NC',
        name: 'North Carolina'
      },
      {
        value: 'ND',
        isocode: 'US-ND',
        name: 'North Dakota'
      },
      {
        value: 'OH',
        isocode: 'US-OH',
        name: 'Ohio'
      },
      {
        value: 'OK',
        isocode: 'US-OK',
        name: 'Oklahoma'
      },
      {
        value: 'OR',
        isocode: 'US-OR',
        name: 'Oregon'
      },
      {
        value: 'PA',
        isocode: 'US-PA',
        name: 'Pennsylvania'
      },
      {
        value: 'RI',
        isocode: 'US-RI',
        name: 'Rhode Island'
      },
      {
        value: 'SC',
        isocode: 'US-SC',
        name: 'South Carolina'
      },
      {
        value: 'SD',
        isocode: 'US-SD',
        name: 'South Dakota'
      },
      {
        value: 'TN',
        isocode: 'US-TN',
        name: 'Tennessee'
      },
      {
        value: 'TX',
        isocode: 'US-TX',
        name: 'Texas'
      },
      {
        value: 'UT',
        isocode: 'US-UT',
        name: 'Utah'
      },
      {
        value: 'VT',
        isocode: 'US-VT',
        name: 'Vermont'
      },
      {
        value: 'VA',
        isocode: 'US-VA',
        name: 'Virginia'
      },
      {
        value: 'WA',
        isocode: 'US-WA',
        name: 'Washington'
      },
      {
        value: 'WV',
        isocode: 'US-WV',
        name: 'West Virginia'
      },
      {
        value: 'WI',
        isocode: 'US-WI',
        name: 'Wisconsin'
      },
      {
        value: 'WY',
        isocode: 'US-WY',
        name: 'Wyoming'
      },
      {
        value: 'DC',
        isocode: 'US-DC',
        name: 'District of Columbia'
      },
      {
        value: 'AS',
        isocode: 'US-AS',
        name: 'American Samoa'
      },
      {
        value: 'GU',
        isocode: 'US-GU',
        name: 'Guam'
      },
      {
        value: 'MP',
        isocode: 'US-MP',
        name: 'Northern Mariana Islands'
      },
      {
        value: 'PR',
        isocode: 'US-PR',
        name: 'Puerto Rico'
      },
      {
        value: 'UM',
        isocode: 'US-UM',
        name: 'United States Minor Outlying Islands'
      },
      {
        value: 'VI',
        isocode: 'US-VI',
        name: 'Virgin Islands, U.S.'
      }
    ]
  },
  {
    alpha2: 'VI',
    name: 'US Virgin Islands',
    subdivisions: [
      {
        value: 'VI',
        isocode: 'VI-VI',
        name: 'US Virgin Islands'
      }
    ]
  }
] as Array<ISOCountryAndStateProvinceRegion>);

export const getCountryName = (
  countrySelectOptions: Array<Option>,
  countryCode: string
) => {
  const label = countrySelectOptions.find(
    option => option.value === countryCode
  )?.label;
  return isString(label) ? label : countryCode;
};
